<script lang="ts">
  import MapOverlay from "./MapOverlay.svelte";
  import { floors } from "./floors.json"
  import floor from "./Floors.svelte";
  import activeFloor from "../floors";
  
</script>

<div>
    {#each floors[$activeFloor]["regions"] as region, i}
      <MapOverlay overlayType={region["type"]} x={region["x"]} y={region["y"]} size_x={region["size_x"]} size_y={region["size_y"]} id={"overlay-"+String(i)} rot={region["rot"] ? region["rot"] : 0} transition={region["transition"]}>
          <a href={region["spreadsheet_ref"]}>{region["name"]}</a>
        
      </MapOverlay>
    {/each}
</div>