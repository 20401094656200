<script lang="ts">

    import { floors } from "./floors.json";
    import { onMount } from 'svelte';
    import activeFloor from "../floors";


    export let overlayType: string;
    export let x: number;
    export let y: number;
    export let rot: number = 0;
    export let transition: string = null;
    export let size_x: number;
    export let size_y: number;
    export let id: string;

    let ix = 0;
    let iy = 0;
    let sx = 0;
    let sy = 0;

    var colour = "";
    var active = false;
    var drag = false;
    var moved = false;

    var div;
    var debug = 0;

    const setProp = (prop, val) => div.style.setProperty(prop, val);
    const getProp = (prop) => div.style.getPropertyValue(prop);

    const setX = (x) => setProp("--x", x);
    const setY = (x) => setProp("--y", x);
    const setSX = (x) => setProp("--size_x", x);
    const setSY = (x) => setProp("--size_y", x);
    const setRot = (x) => setProp("--rot", x);

    const getX = () => Number(getProp("--x"));
    const getY = () => Number(getProp("--y"));
    const getSX = () => Number(getProp("--size_x"));
    const getSY = () => Number(getProp("--size_y"));
    const getRot = () => Number(getProp("--rot"));
    
    

    switch (overlayType) {
        case "room": colour += "rgb(232, 77, 14)"; break;
        case "team": colour += "hex(FF6600)"; break;
        case "stairs": colour += "rgb(76, 97, 114)"; break;
        case "studio": colour += "rgb(255, 227, 61)"; break;
        default: colour += "rgb(121, 184, 41)"; break;
    }

        const roomColours = {
        room: "rgb(232, 77, 14)",
        team: "rgb(121, 184, 41)",
        stairs: "rgb(76, 97, 114)",
        studio: "rgb(255, 227, 61)",
   };


    var offset = { x: 0, y: 0 };

    var image_pos = {
        x: 0,
        y: 0,
        sx: 0,
        sy: 0
    }

    function activate(event) {
        if (debug) {
            const image = document.getElementById("backimg-"+$activeFloor);
            
            offset = {
                x: event.clientX - Number(div.style.getPropertyValue("--x")),
                y: event.clientY - Number(div.style.getPropertyValue("--y")),
            };
            active = !active;
            drag = true;
        } else {
            
            if (transition != null) {
                activeFloor.set(transition);
            }
        }
    }



    
    

    
</script>

<style>
    .map-overlay {
        opacity: 0.25;
        background-color: var(--colour);
        width: var(--size_x);
        height: var(--size_y);
        left: var(--x);
        top: var(--y);

        position: absolute;
        color: var(--colour);
        margin: 0;
        padding: 0;
        transition-property: color, opacity;
        transition-duration: 0.25s;
        display: flex;
        align-items: center;
        justify-content: center;
        transform:rotate(calc(1deg * var(--rot)));
    }
    .map-overlay:hover {
        opacity: 0.8;
        color: rgb(0, 0, 0);
    }
   

</style>


<div on:mousedown="{activate}" id={id} class="map-overlay" style="--x: {x}; --y: {y}; --size_x: {size_x}; --size_y: {size_y}; --colour: {roomColours[overlayType]}; --rot: {rot}">
    <slot />
</div>
